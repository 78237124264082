import React from 'react'

import FooterAwards from './FooterAwards'
import FooterContact from './FooterContact'
import FooterNavigation from './FooterNavigation'
import FooterSubscribe from './FooterSubscribe'
import FooterLegals from './FooterLegals'

const Footer = () => {
  return (
    <footer>
      <FooterAwards />
      <div className="container">
        <div className="flex flex-wrap -mx-8">
          <div className="w-full md:w-1/2 lg:w-2/3 px-8 bg-flush-left bg-sand">
            <div className="text-xs md:text-lg flex flex-wrap relative py-10 xl:py-20 -ml-8 z-20">
              <div className="w-full xl:w-2/5 pl-8 mb-8 xl:mb-0">
                <FooterContact />
              </div>
              <div className="w-full xl:w-3/5 px-8">
                <FooterNavigation />
              </div>
            </div>
          </div>
          <div className="w-full md:w-1/2 lg:w-1/3 px-8 bg-flush-right bg-sand-dark">
            <div className="flex justify-end relative z-20">
              <div className="w-full md:max-w-sm py-10 xl:py-20">
                <FooterSubscribe />
              </div>
            </div>
          </div>
        </div>
      </div>
      <FooterLegals />
    </footer>
  )
}

export default Footer
