import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Link from '../Link'

const PhoneIcon = () => {
  return (
    <svg className="w-6 h-6 fill-current" viewBox="0 0 30 30">
      <path d="M25.22,18.74a2.29,2.29,0,0,0-3.51,0l-2.46,2.45a.51.51,0,0,1-.68.14c-.5-.32-1.1-.54-1.6-.86a25.92,25.92,0,0,1-6.15-5.58,15.05,15.05,0,0,1-2.18-3.54.49.49,0,0,1,.14-.63c.86-.78,1.63-1.59,2.46-2.41a2.31,2.31,0,0,0,0-3.58l-2-2c-.69-.68-1.33-1.36-2-2a2.36,2.36,0,0,0-3.51,0L1.3,3.23A4.27,4.27,0,0,0,0,6a11.37,11.37,0,0,0,.86,4.9,30.36,30.36,0,0,0,5.33,8.84,32.78,32.78,0,0,0,10.94,8.53,16.46,16.46,0,0,0,6,1.77A4.49,4.49,0,0,0,27,28.53c.68-.82,1.5-1.5,2.23-2.26a2.37,2.37,0,0,0,0-3.58Z" />
    </svg>
  )
}

const MenuIcon = () => {
  return (
    <svg className="w-8 fill-current" viewBox="0 0 34 18">
      <path d="M0,0H34V2H0ZM0,8H34v2H0Zm0,8H34v2H0Z" />
    </svg>
  )
}

const CloseMenuIcon = () => {
  return (
    <svg className="w-6 fill-current" viewBox="0 0 27 27">
      <path
        d="M1.4,24.7L24.7,1.4l0.9,0.9L2.3,25.6L1.4,24.7z M2.3,26.3l-1.6-1.6l24-24l1.6,1.6L2.3,26.3z M2.1,24.7l0.2,0.2
		L24.9,2.3l-0.2-0.2L2.1,24.7z M2.3,1.4l23.3,23.3l-0.9,0.9L1.4,2.3L2.3,1.4z M24.7,26.3l-24-24l1.6-1.6l24,24L24.7,26.3z M2.1,2.3
		l22.6,22.6l0.2-0.2L2.3,2.1L2.1,2.3z"
      />
    </svg>
  )
}

const HeaderTools = ({ toggleMenu, menuIsVisible, headerThemeAlt }) => {
  const data = useStaticQuery(graphql`
    query {
      prismicGlobals {
        data {
          phone_number
          app_download_button {
            uid
            url
            link_type
          }
          app_download_button_text
          booking_button {
            uid
            url
            link_type
          }
          booking_button_text
        }
      }
    }
  `)

  const {
    prismicGlobals: { data: item },
  } = data

  const formatTel = tel => {
    tel = tel.replace(/\s/g, '')
    return tel
  }

  return (
    <div
      className="flex-shrink-0 flex items-center xl:h-18 2xl:ml-14"
      // h-18 to match the height of the logo..
    >
      {item.phone_number && (
        <>
          <a
            data-ga-target={'header-phone-button'}
            aria-label="Phone"
            className={`w-12 h-12 hidden xl:flex items-center justify-center border-2 border-current text-white rounded-full mr-5 hover:opacity-50 focus:opacity-75 duration-500 transition-colors ${
              headerThemeAlt ? 'text-phoneAlt' : ''
            }`}
            href={`tel:${formatTel(item.phone_number)}`}
          >
            {PhoneIcon()}
          </a>
          <a
            data-ga-target={'header-phone-button'}
            aria-label="Phone"
            className="w-12 h-12 flex xl:hidden items-center justify-center text-blue hover:text-blue-alt focus:text-blue-alt"
            href={`tel:${formatTel(item.phone_number)}`}
          >
            {PhoneIcon()}
          </a>
        </>
      )}
      <button
        className="w-12 h-12 flex xl:hidden items-center justify-center text-blue hover:text-blue-alt focus:outline-none"
        onClick={() => toggleMenu()}
      >
        {menuIsVisible ? CloseMenuIcon() : MenuIcon()}
      </button>
      <Link
        link={item.app_download_button}
        text={item.app_download_button_text}
        dataGaTarget={'header-app-button'}
        className="hidden xl:inline-flex button-primary min-w-0 mr-2"
      />
      <Link
        link={item.booking_button}
        text={item.booking_button_text}
        dataGaTarget={'header-booking-button'}
        className="hidden xl:inline-flex button-secondary min-w-0"
      />
    </div>
  )
}

export default HeaderTools
