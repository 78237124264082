import React from 'react'
import { Breadcrumb } from 'gatsby-plugin-breadcrumb'

const Breadcrumbs = ({ crumbs, crumbLabel, crumbSeparator }) => {
  const formattedCrumbs = formatCrumbs(crumbs)
  return (
    <div className="relative lg:h-0 mb-1 lg:mb-0 z-30">
      <div className="lg:absolute top-0 inset-x-0">
        <div className="container py-3">
          <Breadcrumb
            crumbs={formattedCrumbs}
            crumbLabel={crumbLabel}
            crumbSeparator={crumbSeparator}
          />
        </div>
      </div>
    </div>
  )
}

function formatCrumbs(crumbs) {
  crumbs.forEach(crumb => {
    let crumbLabel = crumb.crumbLabel.replace(/-/, ' ')
    crumbLabel = `${crumbLabel.charAt(0).toUpperCase()}${crumbLabel.slice(1)}`
    crumb.crumbLabel = crumbLabel

    let crumbPath = ''

    if (crumb.pathname === '/') {
      crumbPath = crumb.pathname
    } else {
      crumbPath =
        crumb.pathname.slice(-1) === '/' ? crumb.pathname : `${crumb.pathname}/`
    }
    crumb.pathname = crumbPath
  })

  return crumbs
}

export default Breadcrumbs
