import React from 'react'

import Image from 'components/Image'
import HeroButton from 'components/Hero/HeroButton'

const ImageHeroSlice = props => {
  const { sliceData } = props

  return (
    <div className="swiper-slide w-full">
      {sliceData.primary.image &&
        (sliceData.primary.image.localFile || sliceData.primary.image.url) && (
          <Image
            className="w-full h-full relative z-10"
            src={sliceData.primary.image.url}
            fluid={sliceData.primary.image.localFile?.childImageSharp?.fluid}
            alt={sliceData.primary.image.alt}
          />
        )}

      {sliceData.primary.heading || sliceData.primary.sub_heading_a ? (
        <div className="hero__heading w-full absolute overflow-hidden z-20">
          <div className="container">
            {sliceData.primary.heading && (
              <div
                className={`w-3/4 md:w-1/2 lg:w-2/5 relative ${
                  sliceData.primary.sub_heading_a ? 'mb-4 md:mb-8' : ''
                }`}
              >
                <div
                  className={`absolute right-0 inset-y-0 w-screen z-10 ${
                    sliceData.primary.heading_strip_colour
                      ? 'opacity-75 '
                      : 'bg-purple-opaque-75'
                  }`}
                  style={{
                    backgroundColor:
                      '#' + sliceData.primary.heading_strip_colour,
                  }}
                ></div>
                <h1 className="relative z-20 font-semibold text-white text-2xl md:text-3xl lg:text-4xl xl:text-5xl leading-none pt-1 md:pt-2 pb-3 md:pb-4 pr-5">
                  {sliceData.primary.heading}
                </h1>
              </div>
            )}
            {sliceData.primary.sub_heading_a && (
              <div
                className={`w-3/4 md:w-1/2 lg:w-2/5 relative ${
                  sliceData.primary.sub_heading_b ? 'mb-1 md:mb-2' : ''
                }`}
              >
                <div
                  className={`absolute right-0 inset-y-0 w-screen z-10 ${
                    sliceData.primary.sub_heading_strip_colour
                      ? 'opacity-75'
                      : 'bg-teal-opaque-75'
                  }`}
                  style={{
                    backgroundColor:
                      '#' + sliceData.primary.sub_heading_strip_colour,
                  }}
                ></div>
                <h2 className="relative z-20 text-white text-2xl md:text-3xl lg:text-4xl xl:text-5xl leading-none pt-1 md:pt-2 pb-3 md:pb-4 pr-5">
                  {sliceData.primary.sub_heading_a}
                </h2>
              </div>
            )}
            {sliceData.primary.sub_heading_b && (
              <div className="w-3/4 md:w-1/2 lg:w-2/5 relative">
                <div
                  className={`absolute right-0 inset-y-0 w-screen z-10 ${
                    sliceData.primary.sub_heading_strip_colour
                      ? 'opacity-75'
                      : 'bg-teal-opaque-75'
                  }`}
                  style={{
                    backgroundColor:
                      '#' + sliceData.primary.sub_heading_strip_colour,
                  }}
                ></div>
                <h2 className="relative z-20 text-white text-2xl md:text-3xl lg:text-4xl xl:text-5xl leading-none pt-1 md:pt-2 pb-3 md:pb-4 pr-5">
                  {sliceData.primary.sub_heading_b}
                </h2>
              </div>
            )}
          </div>
        </div>
      ) : null}

      <HeroButton
        link={sliceData.primary.hero_link}
        text={sliceData.primary.hero_link_text}
      />
    </div>
  )
}

export default ImageHeroSlice
