import React from 'react'
import { StaticQuery, graphql } from 'gatsby'

import Img from 'gatsby-image'

const FooterAwards = () => (
  <StaticQuery
    query={graphql`
      query {
        prismicGlobals {
          data {
            awards {
              image {
                alt
                localFile {
                  childImageSharp {
                    fluid(quality: 90) {
                      ...GatsbyImageSharpFluid_noBase64
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={data => {
      const item = data.prismicGlobals.data
      if (item.awards) {
        return (
          <div className="container text-center pb-8 pt-6 md:pt-12 md:pb-20">
            <h3 className="text-xl md:text-4xl font-semibold mb-10 md:mb-14">
              Our awards
            </h3>
            <div className="flex flex-wrap items-center justify-center -ml-10 md:-ml-24 -mb-8 md:-mb-24">
              {item.awards.map(
                (item, index) =>
                  item.image.localFile && (
                    <div key={index} className="pl-10 md:pl-24 pb-8 md:pb-24">
                      <Img
                        className="w-24 md:w-35"
                        fluid={item.image.localFile.childImageSharp.fluid}
                        alt={item.image.alt}
                      />
                    </div>
                  )
              )}
            </div>
          </div>
        )
      }
    }}
  />
)

export default FooterAwards
