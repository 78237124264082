import React from 'react'
import { Link } from 'gatsby'

const ArrowIcon = () => {
  return (
    <svg
      className="header-sub-nav__arrow text-blue fill-current"
      viewBox="0 0 7.12 11.41"
    >
      <path d="M1.41,11.41,0,10,4.29,5.71,0,1.41,1.41,0,7.12,5.71Z" />
    </svg>
  )
}

const HeaderSubNavigation = ({ parent, items }) => {
  return (
    <ul className="header-sub-nav bg-white shadow-md px-7 py-6 z-50">
      {items.map(
        (item, index) =>
          item.child_link_label && (
            <li key={index} className="mb-6 last:mb-0">
              <Link
                className="flex items-center justify-between link text-lg"
                to={`${parent.primary.link.url}${item.child_link.url}/`}
                activeClassName="text-blue"
                partiallyActive={true}
              >
                {item.child_link_label}
                {ArrowIcon()}
              </Link>
            </li>
          )
      )}
    </ul>
  )
}

export default HeaderSubNavigation
