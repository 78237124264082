import React from 'react'
import Image from 'components/Image'
import HeroButton from '../Hero/HeroButton'

const Hero = ({
  heroRef,
  heroImage,
  heroHeading,
  heroAlt,
  heroButtonText,
  heroButtonLink,
}) => {
  return (
    <div className="relative mb-1" ref={heroRef}>
      <div
        className={`hero relative ${
          heroButtonLink && heroButtonLink.uid ? 'hero--has-button' : ''
        }`}
      >
        {heroImage ? (
          <Image
            className="h-full"
            src={heroImage.url}
            fluid={heroImage.localFile?.childImageSharp?.fluid}
            alt={heroAlt}
          />
        ) : (
          <div className="w-full h-full bg-purple-opaque-75"></div>
        )}
        {heroHeading && (
          <div className="hero__heading w-full absolute overflow-hidden z-20">
            <div className="container">
              <div className="w-3/4 md:w-1/2 lg:w-2/5 relative">
                <div className="absolute right-0 inset-y-0 w-screen z-10 bg-teal opacity-75"></div>
                <h1 className="relative z-20 font-semibold text-white text-2xl md:text-3xl lg:text-4xl xl:text-5xl leading-none pt-1 md:pt-2 pb-3 md:pb-4 pr-5">
                  {heroHeading}
                </h1>
              </div>
            </div>
          </div>
        )}
      </div>
      <HeroButton link={heroButtonLink} text={heroButtonText} />
    </div>
  )
}

export default Hero
