import React from 'react'
import { Link } from 'gatsby'
import { useNavigationItem } from 'utils/navigation'

const ArrowIcon = () => {
  return (
    <svg className="w-full h-full fill-current" viewBox="0 0 22 35.26">
      <path d="M4.37,35.26,0,30.89,13.26,17.63,0,4.37,4.37,0,22,17.63Z" />
    </svg>
  )
}

const HeroButtonElement = ({ text }) => {
  return (
    <>
      <div className="h-full flex md:hidden items-center justify-center font-semibold text-white text-center leading-none">
        <span className="text-sm">{text}</span>
        <span className="w-3 h-3 ml-2 mt-px text-white">{ArrowIcon()}</span>
      </div>
      <div className="h-full hidden md:flex flex-col justify-center font-semibold text-white text-center leading-none">
        <span className="w-full text-lg lg:text-2xl xl:text-3xl mb-2 lg:mb-4 xl:mb-6">
          {text}
        </span>
        <span className="w-full flex items-center justify-center w-full text-sm xl:text-lg text-white leading-none">
          More{' '}
          <span className="w-2 h-2 xl:w-3 xl:h-3 ml-2 mt-1 xl:mt-px">
            {ArrowIcon()}
          </span>
        </span>
      </div>
    </>
  )
}

const HeroButton = ({ link, text }) => {
  const heroLink = useNavigationItem((link && link.uid) || '')

  if (!(link && link.url)) {
    return null
  }

  if (link.link_type === 'Document') {
    return (
      <Link className="hero__button z-20" to={`${heroLink}/`}>
        <HeroButtonElement text={text} />
      </Link>
    )
  } else {
    return (
      <a className="hero__button z-20" href={link.url}>
        <HeroButtonElement text={text} />
      </a>
    )
  }
}

export default HeroButton
