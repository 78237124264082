import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'

import Header from 'components/Header'
import Footer from 'components/Footer'
import Hero from 'components/Hero'
import HeroSlices from 'components/HeroSlices'
import SEO from 'components/SEO'
import Breadcrumbs from 'components/Breadcrumbs'

import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks,
} from 'body-scroll-lock'

class Layout extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      menuIsVisible: false,
      headerThemeAlt: false,
    }
    this.menuRef = React.createRef()
    this.heroRef = React.createRef()
  }
  setVhProperty = () => {
    const vh = window.innerHeight * 0.01
    document.documentElement.style.setProperty('--vh', `${vh}px`)
  }

  resizeEvent = () => {
    this.setVhProperty()
    if (window.innerWidth >= 1024) {
      if (this.state.menuIsVisible) {
        clearAllBodyScrollLocks()
        this.setState({
          menuIsVisible: false,
        })
      }
    }
  }
  componentDidMount = () => {
    this.setVhProperty()
    window.addEventListener('resize', this.resizeEvent)
    window.addEventListener('scroll', this.scrollEvent)
  }

  componentWillUnmount = () => {
    window.removeEventListener('resize', this.resizeEvent)
    clearAllBodyScrollLocks()
  }

  toggleMenu = () => {
    if (!this.state.menuIsVisible) {
      this.setState({ menuIsVisible: true })
      setTimeout(() => {
        disableBodyScroll(this.menuRef.current)
      }, 250) // wait till the .fade transition has finished
    } else {
      this.setState({ menuIsVisible: false })
      enableBodyScroll(this.menuRef.current)
    }
  }

  scrollEvent = () => {
    if (this.heroRef.current) {
      let heroHeight = this.heroRef.current.offsetHeight
      if (window.scrollY > heroHeight && !this.state.headerThemeAlt) {
        this.setState({
          headerThemeAlt: true,
        })
      }
      if (window.scrollY <= heroHeight && this.state.headerThemeAlt) {
        this.setState({
          headerThemeAlt: false,
        })
      }
    }
  }

  render() {
    const {
      children,
      heroImage,
      heroButtonLink,
      heroButtonText,
      heroSlices,
      metaTitle,
      metaDescription,
      pageTitle,
      location,
      crumbs,
      crumbLabel,
    } = this.props

    return (
      <StaticQuery
        query={graphql`
          query {
            site {
              siteMetadata {
                title
                description
              }
            }
          }
        `}
        render={data => (
          <div className="font-sans leading-normal text-base text-charcoal overflow-x-hidden">
            {location && (
              <SEO
                title={metaTitle || data.site.siteMetadata.title}
                description={
                  metaDescription || data.site.siteMetadata.description
                }
                location={location}
              />
            )}
            <Header
              siteTitle={data.site.siteMetadata.title}
              menuIsVisible={this.state.menuIsVisible}
              toggleMenu={this.toggleMenu}
              menuRef={this.menuRef}
              headerThemeAlt={this.state.headerThemeAlt}
            />

            {heroImage && (
              <Hero
                heroRef={this.heroRef}
                heroImage={heroImage}
                heroAlt={heroImage.alt || ``}
                heroHeading={pageTitle && pageTitle}
                heroButtonText={heroButtonText && heroButtonText}
                heroButtonLink={heroButtonLink && heroButtonLink}
              />
            )}
            {heroSlices && (
              <HeroSlices heroRef={this.heroRef} heroSlices={heroSlices} />
            )}
            {crumbs && (
              <Breadcrumbs
                crumbs={crumbs}
                crumbLabel={crumbLabel}
                crumbSeparator=" / "
              />
            )}
            {children}
            <Footer />
          </div>
        )}
      />
    )
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
