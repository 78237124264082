import React from 'react'
import Img from 'gatsby-image'

const Image = ({
  src,
  alt,
  fixed,
  fluid,
  objectFit = 'cover',
  objectPosition = '50% 50%',
  ...props
}) => {
  const updatedFluid = {
    ...fluid,
    sizes: '(max-width: 2800px) 100vw, 2800px',
  }
  return src ? (
    <img
      width="100%"
      height="100%"
      alt={alt}
      src={src}
      loading="lazy"
      style={{ objectFit, objectPosition }}
      {...props}
    />
  ) : (
    <Img {...props} fluid={updatedFluid} fixed={fixed} />
  )
}

export default Image
