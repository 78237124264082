import React from 'react'
import { Link } from 'gatsby'
import { useNavigationItem } from 'utils/navigation'

const LinkComponent = props => {
  const documentLink = useNavigationItem((props.link && props.link.uid) || '')

  if (props.link == null) {
    return null
  }

  const link = documentLink === '/' ? documentLink : `${documentLink}/`

  if (props.link.link_type === 'Document') {
    return (
      <Link
        to={link}
        className={props.className}
        data-ta-target={props.dataGaTarget}
      >
        {props.children ? props.children : props.text}
      </Link>
    )
  } else {
    if (props.link.url == null) {
      return null
    }

    return (
      <a
        href={props.link.url}
        className={props.className}
        data-ta-target={props.dataGaTarget}
      >
        {props.children ? props.children : props.text}
      </a>
    )
  }
}

export default LinkComponent
