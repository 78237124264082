import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Link from '../Link'

const FooterNavigation = () => {
  const data = useStaticQuery(graphql`
    query {
      prismicNavigation {
        data {
          nav_items {
            label1
            link {
              uid
              url
              link_type
            }
          }
        }
      }
    }
  `)

  const item = data.prismicNavigation.data
  if (item.nav_items) {
    return (
      <ul className="columns">
        {item.nav_items.map((item, index) => (
          <li key={index}>
            <Link link={item.link} text={item.label1} className="link" />
          </li>
        ))}
      </ul>
    )
  }
}

export default FooterNavigation
