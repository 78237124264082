import React, { useState } from 'react'
import { StaticQuery, graphql, Link } from 'gatsby'
import HeaderMobileSubNavigation from './HeaderMobileSubNavigation'

const ArrowIcon = () => {
  return (
    <svg className="w-full h-full fill-current" viewBox="0 0 8.06 14.71">
      <path d="M.71,14.71,0,14,6.65,7.35,0,.71.71,0,8.06,7.35Z" />
    </svg>
  )
}

const InstagramIcon = () => {
  return (
    <svg className="w-full h-full fill-current" viewBox="0 0 60 60">
      <path d="M35.74,30A5.74,5.74,0,1,1,30,24.26,5.74,5.74,0,0,1,35.74,30Zm7.69-10.16a5.72,5.72,0,0,0-3.27-3.27A9.56,9.56,0,0,0,37,16c-1.82-.08-2.36-.1-7-.1s-5.14,0-7,.1a9.56,9.56,0,0,0-3.2.59,5.35,5.35,0,0,0-2,1.29,5.43,5.43,0,0,0-1.29,2A9.31,9.31,0,0,0,16,23c-.09,1.82-.1,2.36-.1,7s0,5.14.1,7a9.31,9.31,0,0,0,.59,3.2,5.72,5.72,0,0,0,3.27,3.27A9.31,9.31,0,0,0,23,44c1.82.09,2.36.1,7,.1s5.14,0,7-.1a9.31,9.31,0,0,0,3.2-.59,5.72,5.72,0,0,0,3.27-3.27A9.56,9.56,0,0,0,44,37c.09-1.82.1-2.36.1-7s0-5.14-.1-7A9.31,9.31,0,0,0,43.43,19.84ZM30,38.85A8.85,8.85,0,1,1,38.85,30,8.85,8.85,0,0,1,30,38.85Zm9.2-16a2.07,2.07,0,1,1,2.06-2.07A2.06,2.06,0,0,1,39.2,22.87ZM30,0A30,30,0,1,0,60,30,30,30,0,0,0,30,0ZM47.12,37.1a12.37,12.37,0,0,1-.8,4.18,8.75,8.75,0,0,1-5,5,12.37,12.37,0,0,1-4.18.8c-1.83.09-2.42.11-7.1.11s-5.26,0-7.1-.11a12.37,12.37,0,0,1-4.18-.8,8.85,8.85,0,0,1-5-5,12.37,12.37,0,0,1-.8-4.18c-.09-1.83-.11-2.42-.11-7.1s0-5.27.11-7.1a12.37,12.37,0,0,1,.8-4.18,8.85,8.85,0,0,1,5-5,12.37,12.37,0,0,1,4.18-.8c1.83-.09,2.42-.11,7.1-.11s5.27,0,7.1.11a12.37,12.37,0,0,1,4.18.8,8.77,8.77,0,0,1,5,5,12.37,12.37,0,0,1,.8,4.18c.09,1.83.11,2.42.11,7.1S47.21,35.27,47.12,37.1Z" />
    </svg>
  )
}

const FacebookIcon = () => {
  return (
    <svg className="w-full h-full fill-current" viewBox="0 0 60 60">
      <path d="M34.38,30h-3.2V42H26.39V30H24V25.86h2.39V23.37C26.39,20,27.75,18,31.57,18h3.21v4.14h-2c-1.51,0-1.6.57-1.6,1.64v2.08H34.8L34.38,30ZM30,0A30,30,0,1,0,60,30,30,30,0,0,0,30,0Z" />
    </svg>
  )
}

const HeaderMobileNavigation = ({ menuRef }) => {
  const [selectedSubNavigation, setSelectedSubNavigation] = useState(0)
  const [
    selectedSubNavigationIsOpen,
    setSelectedSubNavigationIsOpen,
  ] = useState(false)

  const handleClick = index => {
    if (selectedSubNavigationIsOpen && selectedSubNavigation === index) {
      setSelectedSubNavigationIsOpen(false)
    } else {
      setSelectedSubNavigationIsOpen(true)
    }
    setSelectedSubNavigation(index)
  }

  return (
    <StaticQuery
      query={graphql`
        query {
          prismicNavigation {
            data {
              body {
                ... on PrismicNavigationBodyNavItem {
                  primary {
                    label
                    link {
                      url
                    }
                  }
                  items {
                    child_link_label
                    child_link {
                      url
                    }
                  }
                }
              }
            }
          }
          prismicGlobals {
            data {
              facebook
              instagram
            }
          }
        }
      `}
      render={data => {
        const item = data.prismicNavigation.data
        if (item.body) {
          return (
            <div
              ref={menuRef}
              className="mobile-nav fixed inset-x-0 bg-sand overflow-y-scroll"
            >
              <ul>
                {item.body.map(
                  (item, index) =>
                    item.primary.label && (
                      <li
                        className="block border-b border-light-grey"
                        key={index}
                      >
                        {item.items.length > 0 ? (
                          <>
                            <button
                              className={`link block relative w-full p-5 text-left leading-none focus:outline-none ${
                                selectedSubNavigation === index
                                  ? 'text-blue'
                                  : ''
                              }`}
                              onClick={() => handleClick(index)}
                            >
                              {item.primary.label}
                              <span className="absolute flex items-center w-5 right-0 inset-y-0 mr-5">
                                <div className="w-5 h-5">{ArrowIcon()}</div>
                              </span>
                            </button>
                            {selectedSubNavigation === index &&
                              selectedSubNavigationIsOpen && (
                                <HeaderMobileSubNavigation
                                  items={item.items}
                                  parent={item}
                                />
                              )}
                          </>
                        ) : (
                          <Link
                            className="link block relative w-full p-5 text-left leading-none"
                            to={`${item.primary.link.url}/`}
                            activeClassName="text-blue"
                            partiallyActive={true}
                          >
                            {item.primary.label}
                          </Link>
                        )}
                      </li>
                    )
                )}
              </ul>
              <ul className="flex -ml-4 p-5">
                <li className="pl-4">
                  {data.prismicGlobals.data.facebook && (
                    <a
                      className="block w-10 h-10 text-blue hover:text-blue-alt transition-color"
                      href={data.prismicGlobals.data.facebook}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {FacebookIcon()}
                    </a>
                  )}
                </li>
                <li className="pl-4">
                  {data.prismicGlobals.data.instagram && (
                    <a
                      className="block w-10 h-10 text-blue hover:text-blue-alt transition-color"
                      href={data.prismicGlobals.data.instagram}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {InstagramIcon()}
                    </a>
                  )}
                </li>
              </ul>
            </div>
          )
        }
      }}
    />
  )
}

export default HeaderMobileNavigation
