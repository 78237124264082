import React, { useEffect, useRef } from 'react'
import ImageHeroSlice from './HeroSliceImage'
import VideoHeroSlice from './HeroSliceVideo'
import Swiper from 'swiper'

const HeroSlices = ({ heroRef, heroSlices }) => {
  const swiper = useRef(null)
  useEffect(() => {
    if (heroSlices.length > 1) {
      new Swiper(swiper.current, {
        effect: 'fade',
        speed: 1500,
        fadeEffect: {
          crossFade: true,
        },
        autoplay: {
          delay: 5000,
        },
        slidesPerView: 1,
        simulateTouch: false,
        pagination: {
          el: '.carousel__pagination',
          type: 'bullets',
          bulletClass: 'carousel__pagination-bullet',
          bulletActiveClass: 'carousel__pagination-bullet--active',
          clickable: true,
        },
      })
    }
    return () => {
      // ..
    }
  })

  return (
    <div className="relative mb-1" ref={heroRef}>
      <div className="hero relative hero--has-sub-heading">
        {heroSlices && (
          <div ref={swiper} className="absolute inset-0 z-10">
            <div
              className={`box-border w-full h-full ${
                heroSlices.length > 1 ? 'swiper-wrapper' : ' '
              }`}
            >
              {heroSlices.map((item, index) => (
                <React.Fragment key={index}>
                  {item.slice_type === 'video' && (
                    <VideoHeroSlice sliceData={item} />
                  )}
                  {item.slice_type === 'image' && (
                    <ImageHeroSlice sliceData={item} />
                  )}
                </React.Fragment>
              ))}
            </div>
            {heroSlices.length > 1 && (
              <div className="carousel__pagination carousel__pagination--hero"></div>
            )}
          </div>
        )}
      </div>
    </div>
  )
}

export default HeroSlices
