import React from 'react'
import { StaticQuery, graphql, Link } from 'gatsby'

import HeaderSubNavigation from './HeaderSubNavigation'

const HeaderNavigation = ({ headerThemeAlt }) => (
  <StaticQuery
    query={graphql`
      query {
        prismicNavigation {
          data {
            body {
              ... on PrismicNavigationBodyNavItem {
                primary {
                  label
                  link {
                    url
                  }
                }
                items {
                  child_link_label
                  child_link {
                    url
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={data => {
      const item = data.prismicNavigation.data
      if (item.body) {
        return (
          <div className="flex-grow hidden xl:block mb-3 2xl:mb-5">
            <ul className="flex flex-wrap items-center">
              {item.body.map(
                (item, index) =>
                  item.primary.label && (
                    <li
                      className={`header-nav-item mx-4 ${
                        item.items.length > 0
                          ? 'header-nav-item--has-sub-nav'
                          : ''
                      }`}
                      key={index}
                    >
                      <Link
                        className={`header-nav-item__link duration-500 transition-colors ${
                          headerThemeAlt ? 'text-charcoal' : 'text-white'
                        }`}
                        to={`${item.primary.link.url}/`}
                        activeClassName="header-nav-item__link--active"
                        partiallyActive={true}
                      >
                        <span>{item.primary.label}</span>
                      </Link>
                      {item.items.length > 0 && (
                        <HeaderSubNavigation
                          key={index}
                          parent={item}
                          items={item.items}
                        />
                      )}
                    </li>
                  )
              )}
            </ul>
          </div>
        )
      }
    }}
  />
)

export default HeaderNavigation
