import React from 'react'
import { Link } from 'gatsby'

const HeaderMobileSubNavigation = ({ items, parent }) => {
  return (
    <ul className="bg-cream border-t border-light-grey p-5">
      <li className="mb-2">
        <Link
          className="link"
          to={`${parent.primary.link.url}/`}
          activeClassName="text-blue"
          partiallyActive={true}
        >
          {parent.primary.label}
        </Link>
      </li>
      {items.map(
        (item, index) =>
          item.child_link_label && (
            <li key={index} className="mb-2 last:mb-0">
              <Link
                className="link"
                to={`${parent.primary.link.url}${item.child_link.url}/`}
                activeClassName="text-blue"
                partiallyActive={true}
              >
                {item.child_link_label}
              </Link>
            </li>
          )
      )}
    </ul>
  )
}

export default HeaderMobileSubNavigation
