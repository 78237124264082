import React from 'react'
import { withFormik, Form, Field } from 'formik'
import addToMailchimp from 'gatsby-plugin-mailchimp'

/** constants */
const wrapperClass = ``
let msgSuccess = `Thanks, you're now subscribed to receive our exclusive deals.`
let msgError =
  'Sorry, something went wrong and an administrator has been notified. Please try again shortly.'

const Success = () => (
  <div className={wrapperClass}>
    <div>{msgSuccess}</div>
  </div>
)

const FormWithError = ({ isSubmitting, errors }) => (
  <Form className={wrapperClass}>
    <h3 className="text-lg lg:text-xl xl:text-2xl font-semibold mb-2 md:mb-3">
      Sign up for exclusive deals
    </h3>
    <div className="mb-2 md:mb-6">
      <label htmlFor="subscribe-name">
        <span className="block text-xs md:text-lg xl:text-xl lg:font-semibold mb-1">
          Name
        </span>
        <Field
          id="subscribe-name"
          className="input"
          type="text"
          name="name"
          required
        />
      </label>
    </div>
    <div className="mb-3 md:mb-6">
      <label htmlFor="subscribe-email">
        <span className="block text-xs md:text-lg xl:text-xl lg:font-semibold mb-1">
          Email
        </span>
        <Field
          id="subscribe-email"
          className="input"
          type="email"
          name="email"
          required
        />
      </label>
    </div>
    <button
      className="button-primary w-full"
      type="submit"
      disabled={isSubmitting}
    >
      Sign up
    </button>
    {/* Error Message */}
    {errors.submit && (
      <div
        className="text-black mt-2"
        dangerouslySetInnerHTML={{ __html: msgError }}
      ></div>
    )}
  </Form>
)

const DefaultForm = props => {
  const { status } = props
  if (status && status.success) {
    return <Success />
  } else {
    return <FormWithError {...props} />
  }
}

const FooterSubscribe = withFormik({
  mapPropsToValues: values => ({
    name: values.name || '',
    email: values.email || '',
  }),
  handleSubmit: async (
    form,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) => {
    try {
      const name1 = form.name
        .split(' ')
        .slice(0, -1)
        .join(' ')
      const name2 = form.name
        .split(' ')
        .slice(-1)
        .join(' ')
      const { result, msg } = await addToMailchimp(form.email, {
        FNAME: name1 ? name1 : name2,
        ...(name1 && {
          FNAME: name1,
          LNAME: name2,
        }),
        ...(!name1 &&
          name2 && {
            FNAME: name2,
          }),
      })
      if (result === 'error') throw new Error(msg)
      resetForm()
      setStatus({ success: true })
      if (window && window.dataLayer) {
        window.dataLayer.push({
          event: 'dealSignUp',
        })
      }
    } catch (error) {
      if (error && error.message && typeof error.message === 'string') {
        msgError = error.message
      }
      // Set errors.submit to TRUE
      setErrors({ submit: true })
      // Reset error.submit to FALSE
      setTimeout(() => {
        setErrors({ submit: false })
      }, 20000)
    } finally {
      setSubmitting(false)
    }
  },
})(DefaultForm)

export default FooterSubscribe
